import { Link } from "gatsby"
import PropTypes from "prop-types"
import React , {Component} from "react"
import "../assets/header.sass"
import "../assets/menu.css"
import "./layout.css"

import Image from "../components/image"

class Header extends Component {
	
		constructor(props) {
			super(props);
			this.state = {
				showModal: false,
			};
			this.handleClick = this.handleClick.bind(this);
		}
	
		// function to handle click
		handleClick() {
			this.setState(prevState => ({
				showModal: !prevState.showModal
			}));
		}
	
		
	
	render(){
		const menuActive = this.state.showModal ? 'phone-menu-toggle' : '';
		const dropdownOptions = this.state.showModal ? 'visible' : ''
		
		return(
			<header>

				<div className="logo-container logo-image-container">
					<Link to="/">
						<Image width="200px" filename="xsignature.png" />
					</Link>
				</div>

				<div className="header-contact-info logo-container">
					<a className="contact-links" href="mailto:thex.signaturecompany@gmail.com">thex.signaturecompany@gmail.com</a>
				</div>
				
				<div className="hamburger-menu-icon-container">
					<div onClick={this.handleClick} className={`dropdown-icon-wrapper phone-menu-toggle-two ${menuActive}`}>
						<Image alt="Logo" filename="list.png"/>
					</div>
        		</div>

				<nav>
					<div className="desktop-menu-options ">
						<Link to="/services"><p>Services</p></Link>
						<Link to="/commercial"><p>FAQ</p></Link>
						<Link to="/contact"><p>Contact Us</p></Link>
						<Link to="/services/clothing"><p>clothing</p></Link>
					</div>
				</nav>

				<div className={`netflix-nav netflix-nav${dropdownOptions} netflix-nav-white netflix-nav-white${dropdownOptions}`}>
					
					<button onClick={this.handleClick} className="netflix-nav-btn netflix-close-btn"><i className="fas fa-times">x</i></button>
					
					{/* <Image className="netflix-logo" src="Tonys-logo-edit.png" alt="Tonys Drain Logo" /> */}
					
					<ul onClick={this.handleClick} className="netflix-list">
						<Link to="/services"> <li>Services</li></Link>
						<Link to="/faq"> <li>FAQ</li></Link>
						<Link to="/contact"> <li>Contact Us</li></Link>
						<Link to="/services/clothing"> <li>Clothing</li></Link>
					</ul>
				</div>

			</header>
	 )
	}
	
	}
	
	Header.propTypes = {
		siteTitle: PropTypes.string,
	}
	
	Header.defaultProps = {
		siteTitle: ``,
	}

export default Header
	